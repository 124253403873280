import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import auth from './auth'
import clients from './clients'
import agreements from './agreements'
import quotations from './quotations'
import counterparties from './counterparties'
import currentUser from './currentUser'
import contacts from './contacts'
import city from './city'
import team from './team'
import legalPerson from './legalPerson'
import services from './services'
import carType from './carType'
import chat from './chat'
import notifications from './notifications'
import template from './template'
import lists from './lists'
import leads from './leads'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    
  },
  
  mutations: {
    
  },
  actions: {
    async api({commit}) {
      return await axios
        .get('/manifest.json', {})
        .then(resp => {
          axios.defaults.headers.common['Auth-Key'] = resp.data.api;
          axios.defaults.baseURL = resp.data.url
          return resp
        })
        .catch(error => console.log(error));
    },
  },
  modules: {
    auth, 
    agreements, 
    clients, 
    quotations, 
    counterparties, 
    currentUser, 
    contacts, 
    city, 
    team, 
    legalPerson,
    carType,
    services,
    chat,
    notifications,
    template,
    lists,
    leads
  },
  
})
