<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
    <div v-if="updateApp">
      <div class="pop-up-bg"></div>
      <div class="pop-up">
        <div>
          <h2 style="font-weight: 400; text-align: center">
            {{$root.langText.needUpdate}}
          </h2>
          <i class="icon-update"></i>
          <div class="pop-up__btn" style="justify-content: center">
            <a class="btn btn-primary" @click.prevent="updateApp2()"
              >{{$root.langText.pageUpdate}}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-rotate">
      <img :src="url + '/img/turn.svg'" alt="" />
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/app.css";
@import "assets/custom.css";
.form__input--plagin{
  margin-bottom: 0;
  width: 100%;
}
.form__input--plagin .form__input{
  width: 100% !important;
}
.list-not{
  margin-top: 20px;
}
// .pop-up{
//   overflow: auto;
// }
</style>


<script>
import LoginLayout from "@/layouts/LoginLayout";
import OtherLayout from "@/layouts/OtherLayout";
import Axios from "axios";

export default {
  data() {
    return {
      updateApp: false,
      interval: null,
      url: "",
    };
  },

  async created() {
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      // if(localStorage.getItem('lang') == null || localStorage.getItem('lang') == 'ua'){
        localStorage.setItem('lang', 'uk');
      // }
    } else{
      if(localStorage.getItem('lang') == 'ua'){
        localStorage.setItem('lang', 'uk')
      } else if(localStorage.getItem('lang') == null){
        localStorage.setItem('lang', 'ru')
      }
    }


    this.url = window.location.origin;
    var app = this;
    this.interval = setInterval(function () {
      if (window.update) {
        app.updateApp = true;
      }
    }, 1000);

    //theme
    if (localStorage.getItem("theme") == "dark") {
      document.body.classList.add("dark");
    }
    var res = await this.$store.dispatch("api");
    this.$root.app = res.data.UniqueID;
    this.$root.titleName = res.data.short_name
    this.$root.currencyName = res.data.currencyName;
    this.$root.currencyIcc = res.data.currencyIcc;

    //logout
    Axios.interceptors.response.use(
      (response) => {
        if (response.data.error === "invalid user api key") {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        } else if (
          response.status === 401 &&
          response.config &&
          !response.config.__isRetryRequest
        ) {
          this.$store.dispatch("logout");
          this.$router.push("/login");
        }
        if (
          response.data.error == "empty data" ||
          response.data.error == "access denied"
        ) {
          this.$router.push(this.$route.meta.url);
        }
        return response;
      },
      (error) => {
        console.log(error);
      }
    );
  },

  methods: {
    updateApp2() {
      this.updateApp = false;
      window.update = false;
      document.location.reload();
    },
  },

  computed: {
    layout() {
      if (this.$route.meta.layout == "login") {
        return "login-layout";
      } else if (this.$route.meta.layout == "other") {
        return "other-layout";
      }
      // return (this.$route.meta.layout || 'other') + '-layout'
    },
  },

  components: {
    LoginLayout,
    OtherLayout,
  },
};
</script>