
export default function lang(lang) {
	if (lang == 'uk') {
		var data = {
			setting: {
				title: 'Мій профіль',
				titleMeta: 'Налаштування',
				contact: 'Мої контакти',
				tabChange: 'Натисніть, щоб змінити',
				setting: 'Мої налаштування',
				password: 'Змінити пароль',
				themeDark: 'Темна тема',
				themeWhite: 'Світла тема',
				lang: 'Мова',
				changePassword: 'Зміна паролю',
				password: 'Поточний пароль',
				newPassword: 'Новий пароль',
				repeatPassword: 'Повторіть, щоб запам’ятати',
				donePassword: 'Вітаємо, Ви маєте новенький пароль',
				errorPassword: 'Неправильний пароль',
				noUser: 'Такого користувача не знайдено',
			},
			menu: {
				home: 'Головна',
				quotes: 'Мої завдання',
				quotesOther: 'Мої котирування',
				order: 'Мої рейси',
				orderOther: 'Мої угоди',
				leads: 'Мої ліди',
				chat: 'Мої чати',
				statistics: 'Статистика',
				menuDirectory: 'Довідник',
				settings: 'Мої налаштування',
				exit: 'Вийти з аккаунту',
				downloadTemplates: 'Шаблони завантаження',
				unloadingTemplates: 'Шаблони розвантаження',
				customerCard: 'Карта клієнта',
				directions: 'Напрямки перевезень',
				transportCompanies: 'Транспорт компанії',
				managers: 'Менеджери',
				carriers: 'Підлеглі перевізники',
				recommendations: 'Рекомендації відправлення',
			},
			directory: {
				clients: 'Клієнти',
				clientsDirection: 'Довідник: Клієнти',
				counterparties: 'Контрагенти',
				counterpartiesDirection: 'Довідник: Контрагенти',
				order: 'Рейси',
				orderOne: 'Рейс',
				orderOther: 'Угоди',
				orderOtherOne: 'Угода',
				quotes: 'Завдання',
				quotesOther: 'Котирування',
				city: 'Міста',
				drivers: 'Водії',
				car: 'Автомобілі',
				trailers: 'Причепи',
				leads: 'Ліди',
				leadOne: 'Лід',
				country: 'Країна',
				carList: 'Список авто',
				containersList: 'Список контейнерів',
				containersNumber: 'Кількість контейнерів'
			},

			input: {
				name: 'Назва',
				name2: 'Найменування',
				fullName: 'Повна назва',
				firstName: "Iм'я",
				author: 'Автор',
				phone: 'Телефон',
				comments: 'Коментарі',
				note: 'Примiтки',
				stateNumber: 'Держ. номер',
				yearManufacture: 'Рік виготовлення',
				counterparty: 'Контрагент',
				agent: 'Агент',
				client: 'Клієнт',
				client2: 'Клієнту',
				date: 'Дата',
				priceConfirmed: 'Ціна підтверджена',
				calculationPF: 'Розрахунок ПФ',
				ndsNot: 'Ціна без ПДВ',
				nds: 'ПДВ',
				priceNds: 'Ціна з ПДВ',
				pf: 'ПФ',
				incomeRateNdsNot: 'Ставка доходу без ПДВ',
				expenseRateNdsNot: 'Ставка витрати без ПДВ',
				ndsIncome: 'ПДВ доходу',
				ndsExpense: 'ПДВ витрати',
				expense: 'Витрата',
				rateReturn: 'Ставка доходу',
				expenseRate: 'Ставка витрати',
				currencyIncome: 'Валюта доходу',
				currencyExpense: 'Валюта витрати',
				dateAdded: 'Дата завантаження',
				paymentDate: 'Дата оплати',
				datePerformed: 'Дата виконаних робіт',
				dateTranshipment: 'Дата перевантаження',
				incomeYe: 'Дохід УЕ',
				expenseYe: 'Витрата УЕ',
				incomePaymentType: 'Вид оплати доходу',
				expensePaymentType: 'Вид оплати витрат',
				incomeRate: 'Курс доходу',
				expenseRate2: 'Курс витрат',
				dateIssueCar: 'Дата видачі авто',
				dateDisbandment: 'Дата розформування',
				driver: 'Водій',
				driverPhone: 'Номер телефону водія',
				car: 'Автомобіль',
				car2: 'Машина',
				carModel: 'Модель авто',
				model: 'Модель',
				carYear: 'Рік авто',
				carYear2: 'Рік випуску',
				typeEngine: 'Вид двигуна',
				volumeEngine: "Об'єм двигуна",
				carPriceKorea: 'Ціна Корея',
				carPriceUk: 'Ціна Україні',
				color: 'Колір',
				mileage: 'Пробіг',
				trailer: 'Причеп',
				number: 'Номер',
				liftingCapacity: 'Вантажопідйомність',
				liftingCapacity2: 'Вантажопідйомність, т',
				note: 'Примітка',
				volume: 'Об’єм',
				volume2: 'Об’єм вантажу, м3',
				individual: 'Фіз. особа',
				individual2: 'Фізична особа',
				individualAddress: 'Фактична адреса',
				legalEntity: 'Юр. особа',
				legalEntity2: 'Юридична особа',
				legalAddress: 'Юридична адреса',
				batch: 'Партія',
				line: 'Лінія',
				address: 'Адреса',
				status: 'Статус',
				source: 'Джерело',
				discount: 'Знижка',
				carrier: 'Перевізник',
				provider: 'Постачальник',
				mainCarrier: 'Головний перевізник',
				additionalSign: 'Додаткова ознака',
				specialControl: 'Особливий контроль',
				blacklist: 'Чорний список',
				city: 'Місто',
				manager: 'Менеджер',
				managerCarrier: 'Менеджер перевізника',
				documentManager: 'Менеджер документального супроводу',
				managerClients: 'Менеджер по роботі з клієнтами',
				managerSales: 'Менеджер активних продажів',
				managerOperations: 'Оперативний менеджер',
				managerPhone: 'Номер телефону менеджера',
				carrierTwo: 'Другий перевізник',
				managerResp: 'Відп. менеджер',
				titleTypeCompany: 'Тип компанії',
				forwarder: 'Експедитор',
				producer: 'Виробник',
				motorShow: 'Автосалон',
				broker: 'Брокер',
				titleDirection: 'Напрямок',
				imports: 'Імпорт',
				export: 'Експорт',
				transit: 'Транзит',
				internal: 'Внутрішні',
				doNotCarry: 'Не возять',
				titleService: 'Послуги',
				titlePlan: 'План',
				freight: 'Фрахт',
				auto: 'Авто',
				brand: 'Марка',
				railway: 'ЖД',
				aviation: 'Авіа',
				lcl: 'LCL',
				teo: 'TEO',
				poo: 'POO',
				pol: 'POL',
				pot: 'POT',
				pot2: 'POT2',
				pod: 'POD',
				fd: 'FD',
				transshipment: 'Перевалка',
				customsCleaning: 'Митне очищення',
				loads: 'Вантажі',
				cargo: 'Вантаж',
				addressClearanceOut: 'к',
				addressUnloading: 'Адреса вивантаження, контакти',
				unloadingPoint: 'Пункт вивантаження',
				addressClearanceIn: 'Адреса затаможки',
				addressDownload: 'Адреса завантаження, контакти',
				downloadPoint: 'Пункт навантаження',
				deliveryWay: 'Шлях доставки',
				history: 'Історія',
				type: 'Тип',
				view: 'Вид',
				weight: 'Вага',
				departure: 'Виїзд',
				return: 'Повернення',
				atClient: 'У клієнта',
				fromClient: 'Від клієнта',
				employeesResponsible: 'Відповідальні працівники',
				operative: 'Оперативник',
				specialConditions: 'Особливі умови',
				pib: 'ПІБ',
				typeFormSite: 'Вид форми на сайті',
				tagsUTM: 'UTM-мітки',
				request: 'Запит',
				historyCommunication: 'Історія спілкування',
				taskType: 'Тип завдання',
				taskDate: 'Дата завдання',
				description: 'Опис',
				participantsExtra: 'Дод. учасники',
				assistant: 'Помiчник',
				logist: 'Логіст',
				trader: 'Трейдер',
				group: 'Група',
				rating: 'Рейтинг на момент вибору',
				borderCrossing: 'Погранперехід',
				overload: 'Перевантаження',
				overloadPlace: 'Місце перевантаження',
				main: 'Основна',
				quantity: 'Кількість',
				resident: 'Не резидент',
				codeEDRPOU: 'Код ЄДРПОУ',
				ipn:'ІПН',
				vin: 'VIN',
				eta: 'ЕТА',
				accountOrders: 'Рахунок на кожен рейс',
				accountOrdersOther: 'Рахунок на кожну угоду',
				monthlyBill: 'Рахунок раз на місяць',
				accountSeparately: 'Кожний рахунок окремо',
				emailMailing: 'E-mail для розсилки',
				mailingAddress: 'Поштова адреса для відправлення пошти',
				legalForm: 'Правова форма',
				post: 'Посада',
				bankCard: 'Банківська карта',
				message: 'Написати повідомлення...',
				based: 'На підставі',
				shipwreck: 'Суднозахід',
				booking: 'Букінг',
				consignment: 'Коносамент',
				statusMbl: 'Статус MBL',
				homeBl: 'Home BL',
				invoiceAmount: 'Сума рахунку',
				amountOfficialPayments: 'Сума офіційних платежів',
				container: 'контейнера',
				location: 'Місцезнаходження',
				conditions: 'Умови',
				valid_to: 'Валидна по',
				ready: 'Готовність вантажу',
			},

			changes: {
				addCity: 'Додати місто',
				city: 'Вибір міста',
				enterData: 'Введіть дані',
				trailer: 'Вибір пречепа',
				team: 'Вибір співробітників',
				services: 'Вибір послуги',
				legalEntity: 'Вибір юридичної особи',
				drivers: 'Вибір водія',
				countries: 'Вибір країни',
				counterpartiesGroup: 'Вибір групи',
				counterparties: 'Вибір контрагента',
				counterpartiesNo: 'Контрагентів немає',
				contact: 'Вибір контактної особи',
				clientsSource: 'Вибір джерела',
				clients: 'Вибір клієнта',
				container: 'Тип контейнера',
				carType: 'Тип машини',
				car: 'Вибір автомобіля',
				organization: 'Вибір организації',
				line: 'Вибір лінії',
				shipCalls: 'Вибір судозаходу',
				brand: 'Вибір марки',
				model: 'Вибір моделі',
				name: 'Найменування',
				incomeNDS: 'Вибір ПДВ доходу',
				paymentTypes: 'Вибір виду оплаты дохода',
				orders: 'Створити угоду',
				save: 'Дані збережено',
				error: 'Щось пішло не так. Спробуйте пізніше.',
				fillIn: 'Заповніть поля',
				resultContact: 'Результат контакту',
				scheduleContact: 'Запланувати контакт',
				create: 'Створив',
				basis: 'Підстава',
				organization: 'Органiзацiя',
				createFlight: 'Створити рейс',
				checkData: 'Перевірте дані',
				income: 'Дохід',
				consumption: 'Витрати',
				profit: 'Прибуток',
				route: 'Вибрати маршрут',
				conditions: 'Вибрати умовы',
				cargo: 'Вибрати вантаж',

			},

			contacts: {
				changeContacts: 'Редагування контакту',
				deleteContacts: 'Видалення контакту',
				addContacts: 'Додати контакт',
				notFound: 'Немає контактних осіб',
				contactInformation: 'Контактна інформація',
				contactsNo: 'Контактів немає',
				contactsView: 'Вид контакту',
				contactPerson: 'Контактна особа',
				contactResult: 'Результат контакту',
				contactNext: 'Наступ. контакт',
				viewContactPerson: 'Перегляд контактної особи',
				creatingContactPerson: 'Створення контактної особи',
				isDelete: 'Ви впевнені, що хочете видалити контакт?',
			},
			statistics: {
				title: 'Моя статистика',
				inProcessing: 'Заявок в обробці',
				sentQuotes: 'Котирувань відправлено',
				ordersOpen: 'Відкритих угод',
				issuedInvoices: 'Рахунків виставлено',
			},
			filters: {
				ordersWork: 'Угоди в роботі',
				filtersSelected: 'Вибрані фільтри',
				flightsWork: 'Рейси в роботі',
				my: 'Мої',
				directionHead: 'Керівник напрямку',
				chooseFilters: 'Виберіть фільтри',
				companyTypeNo: 'Без типу компанії',
				companyType: 'Тип компании',
				loadingСountry: 'Країна завантаження',
				unloadingСountry: 'Країна розвантаження',
				downloadPoint: 'Порт завантаження',
				unloadingPoint: 'Порт розвантаження',
				point: 'Порт',
				main_carrier: 'Головний контрагент',
				main_carrier2: 'Головний контрагент 2',
				carrier: 'Перевізник',
				periodDate: 'Період за датою',
				createDeal: 'Створення',
				completion: 'Завершення',
				eta: 'ЕТА',
				downloadPlan: 'Завантаження план',
				downloadFact: 'Завантаження факт',
				date: 'Дата',
				lastContact: 'Останній контакт',
				nextContact: 'Наступний контакт',
			},

			btnAdd: 'Додати',
			save: 'Зберегти',
			search: 'Пошук',
			delete: 'Видалити',
			cancel: 'Cкасувати',
			cancel2: 'Видмінити',
			apply: 'Застосувати',
			copy: 'Cкопіювати',
			keep: 'Залишити',
			done: 'Виконано',
			from: 'від',
			new: 'Нове',
			newPrimary: 'Нова',
			newSecondary: 'Новий',
			good: 'Файно',
			login: 'Вхід',
			comeIn: 'Увійти',
			general: 'Загальна',
			contactTitle: 'Контакти',
			detail: 'Деталізація',
			customer: 'Замовник',
			route: 'Маршрут',
			employee: 'Співробітник',
			all: 'Скрiзь',
			creator: 'Автор',
			customerFreight: 'Фрахт замовника',
			carrierFreight: 'Фрахт перевізника',
			kickbacksCustomer: 'ОТК замовника',
			kickbacksCarrier: 'ОТК перевізника',
			requestsClients: 'Заявка від замовника',
			requests: 'Заявки',
			isDelete: 'Ви впевнені, що хочете видалити',
			isCopying: 'Ви впевнені, що хочете скопіювати',
			containerMin: 'Конт.',
			file: 'Файли',
			chat: 'Чат',
			needUpdate: 'Потрібне оновлення',
			necessaryInformation:	'Потребує інформування',
			pageUpdate: 'Оновити сторінку',
			info: 'Загальна інформація',
			notFound: 'За вашим запитом нічого не знайдено',
			notFoundMin: 'Не знайдено',
			infoDelete: 'Ви впевнені, що хочете видалити шаблон?',
			more: 'Показати ще',
			result: 'Результат',
			firstFive: 'Першi 5',
			other: 'Інші',
			total: 'Итого',
		}


	
	} else if (lang == 'ru') {
		var data = {
			setting: {
				title: 'Мой Профиль',
				titleMeta: 'Настройка',
				contact: 'Мои контакты',
				tabChange: 'Нажмите, чтобы изменить',
				setting: 'Мои настройки',
				password: 'Изменить пароль',
				themeDark: 'Темная тема',
				themeWhite: 'Светлая тема',
				lang: 'Язык',
				changePassword: 'Изменение пароля',
				password: 'Текущий пароль',
				newPassword: 'Новый пароль',
				repeatPassword: 'Повторите, чтобы запомнить',
				donePassword: 'Поздравляем, Вы новенький пароль',
				errorPassword: 'Неправильный пароль',
				noUser: 'Такого пользователя не найдено',
			},
			menu: {
				home: 'Главная',
				quotes: 'Мои задания',
				quotesOther: 'Мои котировки',
				order: 'Мои рейсы',
				orderOther: 'Мои сделки',
				leads: 'Мои лиды',
				chat: 'Мои чаты',
				statistics: 'Статистика',
				menuDirectory: 'Справочник',
				settings: 'Мои настройки',
				exit: 'Выйти из аккаунта',
				downloadTemplates: 'Шаблоны загрузки',
				unloadingTemplates: 'Шаблоны разгрузки',
				customerCard: 'Карта клиента',
				directions: 'Направления перевозок',
				transportCompanies: 'Транспорт компании',
				managers: 'Менеджеры',
				carriers: 'Подчиненные перевозчики',
				recommendations: 'Рекомендации отправления',
			},
			directory: {
				clients: 'Клиенты',
				clientsDirection: 'Справочник: Клиенты',
				counterparties: 'Контрагенты',
				counterpartiesDirection: 'Справочник: Контрагенты',
				order: 'Рейсы',
				orderOne: 'Рейс',
				orderOther: 'Сделки',
				orderOtherOne: 'Сделка',
				quotes: 'Задание',
				quotesOther: 'Котировка',
				city: 'Города',
				drivers: 'Водители',
				car: 'Автомобили',
				trailers: 'Прицепы',
				leads: 'Лиды',
				leadOne: 'Лид',
				country: 'Страна',
				carList: 'Список авто',
				containersList: 'Список контейнеров',
				containersNumber: 'Количество контейнеров'
			},

			input: {
				name: 'Название',
				name2: 'Наименование',
				fullName: 'Полное название',
				firstName: "Имя",
				author: 'Автор',
				phone: 'Телефон',
				comments: 'Комментарии',
				note: 'Примечания',
				stateNumber: 'Гос. номер',
				yearManufacture: 'Год изготовления',
				counterparty: 'Контрагент',
				agent: 'Агент',
				client: 'Клиент',
				client2: 'Клиенту',
				date: 'Дата',
				priceConfirmed: 'Цена подтверждена',
				calculationPF: 'Расчет ПФ',
				ndsNot: 'Цена без НДС',
				nds: 'НДС',
				priceNds: 'Цена с НДС',
				pf: 'ПФ',
				incomeRateNdsNot: 'Ставка дохода без НДС',
				expenseRateNdsNot: 'Ставка расходы без НДС',
				ndsIncome: 'НДС дохода',
				ndsExpense: 'НДС расходы',
				expense: 'Расход',
				rateReturn: 'Ставка дохода',
				expenseRate: 'Ставка расхода',
				currencyIncome: 'Валюта дохода',
				currencyExpense: 'Валюта расходы',
				dateAdded: 'Дата загрузки',
				paymentDate: 'Дата оплаты',
				datePerformed: 'Дата выполненных работ',
				dateTranshipment: 'Дата перегрузки',
				incomeYe: 'Доход УЕ',
				expenseYe: 'Расход УЕ',
				incomePaymentType: 'Вид оплаты дохода',
				expensePaymentType: 'Вид оплаты расходов',
				incomeRate: 'Курс дохода',
				expenseRate2: 'Курс расхода',
				dateIssueCar: 'Дата выдачи авто',
				dateDisbandment: 'Дата расформирования',
				driver: 'Водитель',
				driverPhone: 'Номер телефона водителя',
				car: 'Автомобиль',
				car2: 'Машина',
				carModel: 'Модель авто',
				model: 'Модель',
				carYear: 'Год авто',
				carYear2: 'Год выпуска',
				typeEngine: 'Вид двигателя',
				volumeEngine: "Объем двигателя",
				carPriceKorea: 'Цена Корея',
				carPriceUk: 'Цена Украины',
				color: 'Цвет',
				mileage: 'Пробег',
				trailer: 'Прицеп',
				number: 'Номер',
				liftingCapacity: 'Грузоподъемность',
				liftingCapacity2: 'Грузоподъемность, т',
				note: 'Примечание',
				volume: 'Объем',
				volume2: 'Объем груза, м3',
				individual: 'Физ. лицо',
				individual2: 'Физическое лицо',
				individualAddress: 'Фактический адрес',
				legalEntity: 'Юр. лицо',
				legalEntity2: 'Юридическое лицо',
				legalAddress: 'Юридический адресс',
				batch: 'Партия',
				line: 'Линия',
				address: 'Адрес',
				status: 'Статус',
				source: 'Источник',
				discount: 'Скидка',
				carrier: 'Перевозчик',
				provider: 'Поставщик',
				mainCarrier: 'Главный перевозчик',
				additionalSign: 'Дополнительный признак',
				specialControl: 'Особый контроль',
				blacklist: 'Черный список',
				city: 'Город',
				manager: 'Менеджер',
				managerCarrier: 'Менеджер перевозчика',
				documentManager: 'Менеджер документального сопровождения',
				managerClients: 'Менеджер по работе с клиентами',
				managerSales: 'Менеджер активных продаж',
				managerOperations: 'Оперативный менеджер',
				managerPhone: 'Номер телефона менеджера',
				carrierTwo: 'Второй перевозчик',
				managerResp: 'Отв. менеджер',
				titleTypeCompany: 'Тип компании',
				forwarder: 'Експедитор',
				producer: 'Производитель',
				motorShow: 'Автосалон',
				broker: 'Брокер',
				titleDirection: 'Направление',
				imports: 'Импорт',
				export: 'Экспорт',
				transit: 'Транзит',
				internal: 'Внутренние',
				doNotCarry: 'Не возят',
				titleService: 'Услуги',
				titlePlan: 'План',
				freight: 'Фрахт',
				auto: 'Авто',
				brand: 'Марка',
				railway: 'ЖД',
				aviation: 'Авиа',
				lcl: 'LCL',
				teo: 'TEO',
				poo: 'POO',
				pol: 'POL',
				pot: 'POT',
				pot2: 'POT2',
				pod: 'POD',
				fd: 'FD',
				transshipment: 'Перевалка',
				customsCleaning: 'Таможенная очистка',
				loads: 'Грузы',
				cargo: 'Груз',
				addressClearanceOut: 'Адрес растаможки',
				addressUnloading: 'Адрес выгрузки, контакты',
				unloadingPoint: 'Пункт выгрузки',
				addressClearanceIn: 'Адрес затаможки',
				addressDownload: 'Адрес загрузки, контакти',
				downloadPoint: 'Пункт погрузки',
				deliveryWay: 'Путь доставки',
				history: 'История',
				type: 'Тип',
				view: 'Вид',
				weight: 'Вес',
				departure: 'Выезд',
				return: 'Возвращение',
				atClient: 'У клиента',
				fromClient: 'От клиента',
				employeesResponsible: 'Ответственные работники',
				operative: 'Оперативник',
				specialConditions: 'Особые условия',
				pib: 'ФИО',
				typeFormSite: 'Вид формы на сайте',
				tagsUTM: 'UTM-метки',
				request: 'Запрос',
				historyCommunication: 'История общения',
				taskType: 'Тип задачи',
				taskDate: 'Дата задачи',
				description: 'Описание',
				participantsExtra: 'Доп. участники',
				assistant: 'Помощник',
				logist: 'Логист',
				trader: 'Трейдер',
				group: 'Группа',
				rating: 'Рейтинг на момент выбора',
				borderCrossing: 'Погранпереход',
				overload: 'Перегрузка',
				overloadPlace: 'Место перегрузки',
				main: 'Основная',
				quantity: 'Количество',
				resident: 'Не резидент',
				codeEDRPOU: 'Код ЕГРПОУ',
				ipn:'ИНН',
				vin: 'VIN',
				eta: 'ЕТА',
				accountOrders: 'Счет на каждый рейс',
				accountOrdersOther: 'Счет на каждую сделку',
				monthlyBill: 'Счет раз в месяц',
				accountSeparately: 'Каждый счет отдельно',
				emailMailing: 'E-mail для рассылки',
				mailingAddress: 'Почтовый адрес для отправки почты',
				legalForm: 'Правовая форма',
				post: 'Должность',
				bankCard: 'Банковская карта',
				message: 'Написать сообщение...',
				based: 'На основании',
				shipwreck: 'Судозаход',
				booking: 'Букинг',
				consignment: 'Коносамент',
				statusMbl: 'Статус MBL',
				homeBl: 'Home BL',
				invoiceAmount: 'Сумма счета',
				amountOfficialPayments: 'Сумма официальных платежей',
				container: 'контейнера',
				location: 'Местонахождение',
				conditions: 'Условия',
				valid_to: 'Валидна по:',
				ready: 'Готовность груза',
			},

			changes: {
				addCity: 'Добавить город',
				city: 'Выбор города',
				enterData: 'Введите данные',
				trailer: 'Выбор прицепа',
				team: 'Выбор сотрудников',
				services: 'Выбор услуги',
				legalEntity: 'Выбор юридического лица',
				drivers: 'Выбор водителя',
				countries: 'Выбор страны',
				counterpartiesGroup: 'Выбор группы',
				counterparties: 'Выбор контрагента',
				counterpartiesNo: 'Контрагентов нет',
				contact: 'Выбор контактного лица',
				clientsSource: 'Выбор источника',
				clients: 'Выбор клиента',
				container: 'Тип контейнера',
				carType: 'Тип машины',
				car: 'Выбор автомобиля',
				organization: 'Выбор организации',
				line: 'Выбор линии',
				shipCalls: 'Выбор судозахода',
				brand: 'Выбор марки',
				model: 'Выбор модели',
				name: 'Наименование',
				incomeNDS: 'Выбор НДС доходу',
				paymentTypes: 'Выбор вида оплаты дохода',
				orders: 'Создать сделку',
				createFlight: 'Создать рейс',
				save: 'Данные сохранены',
				error: 'Что-то пошло не так. Попробуйте позже.',
				fillIn: 'Заполните поля',
				resultContact: 'Результат контакта',
				scheduleContact: 'Запланировать контакт',
				create: 'Создал',
				basis: 'Основание',
				organization: 'Организация',
				checkData: 'Проверьте данные',
				income: 'Доход',
				consumption: 'Расходы',
				profit: 'Прибыль',
				route: 'Выбрать маршрут',
				conditions: 'Выбрать Условия',
				cargo: 'Выбрать груз',
			},

			contacts: {
				changeContacts: 'Редактирование контакта',
				deleteContacts: 'Удаление контакта',
				addContacts: 'Добавить контакт',
				notFound: 'Нет контактных лиц',
				contactInformation: 'Контактная информация',
				contactsNo: 'Контактов нет',
				contactsView: 'Вид контакта',
				contactPerson: 'Контактное лицо',
				contactResult: 'Результат контакта',
				contactNext: 'След. контакт',
				viewContactPerson: 'Просмотр контактного лица',
				creatingContactPerson: 'Создание контактного лица',
				isDelete: 'Вы уверены, что хотите удалить контакт?',
			},
			statistics: {
				title: 'Моя статистика',
				inProcessing: 'Заявок в обработке',
				sentQuotes: 'Котировок отправлено',
				ordersOpen: 'Открытых сделок',
				issuedInvoices: 'Счетов выставленно',
			},
			filters: {
				ordersWork: 'Сделки в работе',
				filtersSelected: 'Выбранные фильтры',
				flightsWork: 'Рейсы в работе',
				my: 'Мои',
				directionHead: 'Руководитель направления',
				chooseFilters: 'Выберите фильтры',
				companyTypeNo: 'Без типа компании',
				companyType: 'Тип компании',
				loadingСountry: 'Страна загрузки',
				unloadingСountry: 'Страна выгрузки',
				downloadPoint: 'Порт загрузки',
				unloadingPoint: 'Порт выгрузки',
				point: 'Порт',
				main_carrier: 'Главный контрагент',
				main_carrier2: 'Главный контрагент 2',
				carrier: 'Перевозчик',
				periodDate: 'Период по дате',
				createDeal: 'Cоздание',
				completion: 'Завершение',
				eta: 'ЕТА',
				downloadPlan: 'Загрузка план',
				downloadFact: 'Загрузка факт',
				date: 'Дата',
				lastContact: 'Последний контакт',
				nextContact: 'Следующий контакт',
			},

			btnAdd: 'Добавить',
			save: 'Сохранить',
			search: 'Поиск',
			delete: 'Удалить',
			cancel: 'Отменить',
			cancel2: 'Отменить',
			apply: 'Применить',
			copy: 'Скопировать',
			keep: 'Оставить',
			done: 'Выполнено',
			from: 'от',
			new: 'Новое',
			newPrimary: 'Новая',
			newSecondary: 'Новый',
			good: 'Прекрасно',
			login: 'Вход',
			comeIn: 'Войти',
			general: 'Общая',
			contactTitle: 'Контакты',
			detail: 'Детализация',
			customer: 'Заказчик',
			route: 'Маршрут',
			employee: 'Сотрудник',
			all: 'Везде',
			creator: 'Автор',
			customerFreight: 'Фрахт заказчика',
			carrierFreight: 'Фрахт перевозчика',
			kickbacksCustomer: 'ОТК заказчика',
			kickbacksCarrier: 'ОТК перевозчика',
			requestsClients: 'Заявка от заказчика',
			requests: 'Заявки',
			isDelete: 'Вы уверены, что хотите удалить',
			isCopying: 'Вы уверены, что хотите скопировать',
			containerMin: 'Конт.',
			file: 'Файлы',
			chat: 'Чат',
			needUpdate: 'Требуется обновление',
			necessaryInformation:	'Требует информирования',
			pageUpdate: 'Обновить страницу',
			info: 'Общая информация',
			notFound: 'По вашему запросу ничего не найдено',
			notFoundMin: 'Не найдено',
			infoDelete: 'Вы уверены, что хотите удалить шаблон?',
			more: 'Показать еще',
			result: 'Результат',
			firstFive: 'Первые 5',
			other: 'Другие',
			total: 'Итого',
		}
	}

	return data
}