<template>
  <transition name="fade">
    <div v-if="popup.flag">
      <div class="pop-up-bg" @click.prevent="$emit('close')"></div>
      <div class="pop-up">
        <a class="pop-up__close icon icon-close" @click.prevent="$emit('close')"></a>

        <h2 v-if="this.popup.mod == 'download'">{{$root.langText.menu.downloadTemplates}} ({{quantityList}})</h2>
        <h2 v-else >{{$root.langText.menu.unloadingTemplates}} <span v-if="quantityList !== 0">({{quantityList}})</span></h2>
        <!-- <Search :filterNone="'none'" @inputchange="searchBtn"/>
        <p class="list-not" v-if="listNot">За вашим запитом нічого не знайдено</p> -->
        <ul class="block-list">
          <li
            v-for="(item, index) in list"
            :key="index"
            :data-id="item.id"
            @click.prevent="selectItem({item, mod: popup.mod})"
          >

            <div v-if="popup.mod == 'download'">
              <h4>{{item.poo_name}}</h4>
              <b v-if="item.loading_address">{{$root.langText.input.addressDownload}}</b>
              <p style="margin-top: 5px">
                {{(item.loading_address).length > 50 ? (item.loading_address).substr(0, 50) + ' ...' : item.loading_address}}  
              </p>
              <b v-if="item.customs_address_from">{{$root.langText.input.addressClearanceIn}}</b>
              <p style="margin-top: 5px">
                {{(item.customs_address_from).length > 50 ? (item.customs_address_from).substr(0, 50) + ' ...' : item.customs_address_from}}  
              </p>
            </div>


            <div v-else>
              <h4>{{item.fd_name}}</h4>
              <b v-if="item.unloading_address">{{$root.langText.input.addressUnloading}}</b>
              <p style="margin-top: 5px">
                {{(item.unloading_address).length > 50 ? (item.unloading_address).substr(0, 50) + ' ...' : item.unloading_address}}  
              </p>
              <b v-if="item.customs_address_to">{{$root.langText.input.addressClearanceOut}}</b>
              <p style="margin-top: 5px">
                {{(item.customs_address_to).length > 50 ? (item.customs_address_to).substr(0, 50) + ' ...' : item.customs_address_to}}
              </p>
            </div>

          </li>
        </ul>
        <div style="position: relative; height: 73px">
          <Pagination v-if="quantityList && quantityList > params.limit" @pagination="pagination" :dataList="{quantityList, params}"/>
          <a class="btn btn-transparent" v-if="popup.edit" style="position:absolute;top: 20px; padding: 6px 20px;" @click.prevent="add = !add">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
        </div>
      </div>
      <div v-if="add">
        <Handbook :data="popup2" @closePopup="closePopup" @close="add = false, popup2.mod = 'created', popup2.info = ''" />
      </div>
    </div>
  </transition>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to{
    opacity: 0;
  }


</style>


<script>
import Pagination from "@/components/app/Pagination"
import Search from "@/components/app/Search"
import Handbook from "@/components/app/Handbook"

export default {
  props: ['popup', 'idClient'],

  data:() => ({
    add: false,
    popup2: {
      info: '',
      mod: 'created',
      modPopup: 'templatesDownload',
      quantityList: '',
    },

    list: [],
    quantityList: '',
    //pagination
    params: {
      start: 0, 
      limit: 20,
    },
    arrFilters: '',
    searchData: '',
    filtersData: '',
    listNot: false,
    
  }),




  async mounted() {
    //base
    if(this.idClient){
      this.filtersData = 'f[client_id]='+this.idClient
      this.arrFilters = this.filtersData
    }

    if(this.popup.mod == 'download'){
      this.popup2.modPopup = 'templatesDownload'
      var res = await this.$store.dispatch('getTemplateLoading', {params: this.params, data: this.arrFilters})
    } else{
      this.popup2.modPopup = 'templatesUnloading'
      var res = await this.$store.dispatch('getTemplateUnloading', {params: this.params, data: this.arrFilters})
    }
    this.list = res.data.list;
    this.quantityList = res.data.total_num
    this.popup2.quantityList = this.quantityList
    if(this.quantityList == 0){
      this.listNot = true
    } else{
      this.listNot = false
    }
    this.popup2.idClient = this.idClient
  },
  methods: {


    selectItem(data){
      if(this.popup.edit){
        this.add = true
        this.popup2.info = data.item
        this.popup2.mod = 'update'
      } else{
        this.$emit('update', data)
      } 
    },
    
    async closePopup(){
      this.add = false
      if(this.popup.mod == 'download'){
        var res = await this.$store.dispatch('getTemplateLoading', {params: this.params, data: this.arrFilters})
      } else{
        var res = await this.$store.dispatch('getTemplateUnloading', {params: this.params, data: this.arrFilters})
      }
      this.list = res.data.list
      this.quantityList = res.data.total_num

      this.add = false
      this.popup2.mod = 'created'
      this.popup2.info = ''
    },

    //search
    async searchBtn(data) {
      this.searchData = data.search
      this.arrFilters = this.filtersData + '&' + this.searchData
      if(this.popup.mod == 'download'){
        var res = await this.$store.dispatch('getTemplateLoading', {params: this.params, data: this.arrFilters})
      } else{
        var res = await this.$store.dispatch('getTemplateUnloading', {params: this.params, data: this.arrFilters})
      }
      this.list = res.data.list
      this.arrFilters = data.search
      this.quantityList = res.data.total_num
      if(res.data.total_num == 0){
        this.listNot = true
      } else{
        this.listNot = false
      }
    },

    
    async pagination(data){
      this.params.start = data.start
      if(this.popup.mod == 'download'){
        var res = await this.$store.dispatch('getTemplateLoading', {params: this.params, data: this.arrFilters})
      } else{
        var res = await this.$store.dispatch('getTemplateUnloading', {params: this.params, data: this.arrFilters})
      }
      this.list = res.data.list;
    }
  },
  components:{
    Pagination, Search, Autocomplete: () => import('@/components/app/Autocomplete.vue'), Handbook
  }
  
}
</script>